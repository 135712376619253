import React from "react";

const CoptBtn = ({ color }: { color: string }) => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_44_582)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.202 2.12006H2.06856C1.76906 2.12006 1.42871 2.26981 1.42871 2.56931V10.792C1.42871 11.0916 1.76906 11.5 2.06856 11.5H7.9225C8.222 11.5 8.37175 11.0916 8.37175 10.8056V9.7785H7.28264V10.4108H2.51782V3.19554H3.202V2.12006Z"
          fill={color}
        />
        <path
          d="M9.6516 10.0025H3.81131C3.51181 10.0025 3.07617 9.56679 3.07617 9.26734V1.04456C3.07617 0.745057 3.51181 0.608917 3.81131 0.608917H6.69744C6.84719 0.608917 6.98334 0.622538 7.09225 0.717834L9.95114 3.78094C10.0464 3.87624 10.0056 3.98514 10.0056 4.1349V9.28088C10.0056 9.56679 9.95114 10.0025 9.6516 10.0025ZM4.15166 8.91334H8.91649V4.35271L6.46601 1.69802H4.15166V8.91334Z"
          fill={color}
        />
        <path
          d="M9.51913 4.52957H6.68045C6.40041 4.52957 6.03125 4.23679 6.03125 3.95675V1.11809C6.03125 0.914423 6.22219 0.723481 6.41314 0.647105C6.60407 0.570728 6.85867 0.60892 6.99869 0.761672L9.85006 3.60032C9.9901 3.74034 10.041 3.99494 9.96463 4.18588C9.901 4.37682 9.7228 4.52957 9.51913 4.52957ZM7.0496 3.51121H8.29708L7.0496 2.34012V3.51121Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_44_582">
          <rect
            width="9.30769"
            height="11"
            fill="white"
            transform="translate(0.898438 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CoptBtn;
