import React from "react";
const warnIcon = require("../../../EventGalleryMobile/styles/icon-red-warn.svg");

interface PromptClearPrepaidCartProps {
  clearCart: Function;
  closePopup: Function;
}

const PromptClearPrepaidCart = ({
  clearCart,
  closePopup
}: PromptClearPrepaidCartProps) => {
  return (
    <div>
      <div
        id="filter-dialog"
        className="modal fade in geo-modal geo-modal--small geo-modal__remove-offer"
        role="dialog"
      >
        <div className="modal-dialog filter-dialog-content">
          <div className="modal-header">
            &nbsp;
            <button
              type="button"
              className="close"
              onClick={() => {
                closePopup();
              }}
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="geo-modal__header geo-modal--warning-bg">
            <img
              className="geo-modal__warn-icon"
              src={warnIcon}
              alt="exclamation-warn"
            />
            <h4>Please clear your cart</h4>
          </div>
          <div className="geo-modal__body">
            <p>
              This offer applies to only one (1) selfie per event. Please clear
              the cart in order to upload a selfie.
            </p>
          </div>
          <div className="modal-footer geo-modal__actions dont-break">
            <a
              className="geo-btn geo-btn--link  geo-btn--caps"
              style={{ backgroundColor: "#C1C1C1", color: "white" }}
              onClick={() => {
                closePopup();
              }}
            >
              Cancel
            </a>
            <a
              className="geo-btn geo-btn--secondary geo-btn--caps"
              style={{ padding: "12px 30px", marginLeft: "15px" }}
              onClick={async () => {
                await clearCart();
                closePopup();
              }}
            >
              Clear Cart
            </a>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in" />
    </div>
  );
};

export default PromptClearPrepaidCart;
