import React from "react";

const ShareIcon = (props: { color: string | undefined }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="11.5" stroke={props.color} />
      <path
        d="M14.3017 14.128C13.6765 14.128 13.1207 14.3712 12.6865 14.7706L10.5329 13.2075C10.6198 12.9644 10.6719 12.6865 10.6719 12.4086C10.6719 12.1308 10.6198 11.8702 10.5329 11.6097L12.6865 10.0466C13.1033 10.4461 13.6765 10.6892 14.3017 10.6892C15.6043 10.6892 16.6463 9.62983 16.6463 8.34462C16.6463 7.04206 15.5869 6 14.3017 6C12.9991 6 11.9571 7.05942 11.9571 8.34462C11.9571 8.5183 11.9744 8.67461 12.0092 8.84829L9.71667 10.5156C9.33458 10.2377 8.84828 10.064 8.34462 10.064C7.04206 10.064 6 11.1234 6 12.4086C6 13.7112 7.05942 14.7533 8.34462 14.7533C8.86565 14.7533 9.33458 14.5796 9.71667 14.3017L12.0092 15.9516C11.9744 16.1079 11.9571 16.2816 11.9571 16.4553C11.9571 17.7579 13.0165 18.7999 14.3017 18.7999C15.6043 18.7999 16.6463 17.7405 16.6463 16.4553C16.6463 15.1875 15.5869 14.128 14.3017 14.128ZM14.3017 7.38941C14.8401 7.38941 15.2743 7.82359 15.2743 8.36199C15.2743 8.90038 14.8401 9.33457 14.3017 9.33457C13.7633 9.33457 13.3291 8.90038 13.3291 8.36199C13.3291 7.82359 13.7633 7.38941 14.3017 7.38941ZM7.35468 12.426C7.35468 11.8876 7.78887 11.4534 8.32726 11.4534C8.63988 11.4534 8.91776 11.5923 9.09144 11.8181C9.21301 11.9744 9.29984 12.1829 9.29984 12.4086C9.29984 12.6344 9.23038 12.8428 9.09144 12.9991C8.91776 13.2249 8.63988 13.3639 8.32726 13.3639C7.78887 13.3986 7.35468 12.9644 7.35468 12.426ZM14.3017 17.4452C13.7633 17.4452 13.3291 17.011 13.3291 16.4726C13.3291 15.9342 13.7633 15.5001 14.3017 15.5001C14.8401 15.5001 15.2743 15.9342 15.2743 16.4726C15.2569 17.011 14.8227 17.4452 14.3017 17.4452Z"
        fill={props.color}
      />
    </svg>
  );
};
export default ShareIcon;
