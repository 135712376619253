import React from "react";
const saveIcon = require("../styles/save.svg");
const downloadIcon = require("../styles/download.svg");

const BulkTagging = ({
  showBulkTagging,
  selectAllVisible,
  listOfMediaId,
  filterActive,
  allVisible,
  curatePhotos,
  hideOrUnhideText,
  confirmDelete,
  canDeletePhotos,
  colorStyling
}: {
  showBulkTagging: Function;
  selectAllVisible: Function;
  curatePhotos: Function;
  confirmDelete: Function;
  listOfMediaId: Array<{ id: number; hidden: boolean }>;
  filterActive: boolean;
  allVisible: boolean;
  hideOrUnhideText: string;
  canDeletePhotos: boolean;
  colorStyling: string;
}) => {
  const handleHideTooltip = () =>
    //@ts-ignore
    $('#hide-tooltip [data-toggle="tooltip"]').tooltip();

  const handleToggle = (msg: string) =>
    $(`#hide-tooltip [data-toggle="tooltip"]`)
      .attr("data-original-title", `${msg}`)
      //@ts-ignore
      .tooltip("show");
  return (
    <div className="geo-tag-b">
      <div className="geo-tag-b__desc">Select photos to apply tags</div>
      <div className="geo-tag-b__actions">
        {filterActive && (
          <span className="check-radio checkmark">
            <label htmlFor="select-all-vis" className="geo-tag-b__selectall">
              <input
                id="select-all-vis"
                type="checkbox"
                checked={allVisible}
                className="geo-form__checkbox"
                onChange={() => selectAllVisible()}
              />
              <span className="r-dot"></span>
              <span className="r-desc">Select all visible</span>
            </label>
          </span>
        )}

        <button
          className="geo-btn geo-btn--secondary-ghost geo-btn--caps geo-btn--small"
          onClick={() => showBulkTagging()}
          disabled={listOfMediaId.length < 1}
          style={{ color: colorStyling, marginRight: 10 }}
        >
          Tag
        </button>
        <>
          <div id="hide-tooltip">
            <button
              className="geo-btn geo-btn--secondary-ghost geo-btn--caps geo-btn--small"
              onClick={() => curatePhotos()}
              disabled={listOfMediaId.length < 1}
              style={{ marginRight: 10, color: colorStyling }}
              data-toggle="tooltip"
              data-placement="top"
              onMouseEnter={() =>
                handleToggle(
                  "Hidden photos will not display in a published gallery"
                )
              }
              onMouseLeave={() => handleHideTooltip()}
            >
              {hideOrUnhideText}
            </button>
          </div>
          {canDeletePhotos && (
            <button
              className="geo-btn geo-btn--secondary-ghost geo-btn--caps geo-btn--small"
              onClick={() => confirmDelete()}
              disabled={listOfMediaId.length < 1}
              style={{ color: colorStyling }}
            >
              Delete
            </button>
          )}
        </>
        {/* <button disabled={listOfMediaId.length < 1}>
          <img src={saveIcon} />{" "}
        </button>
        <button disabled={listOfMediaId.length < 1}>
          <img src={downloadIcon} />{" "}
        </button> */}
      </div>
    </div>
  );
};

export default BulkTagging;
