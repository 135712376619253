// import 'core-js/stable'
// import 'regenerator-runtime/runtime'

import ReactOnRails from "react-on-rails";
import BrowseEvent from "../bundles/BrowseEvent/components/index";
import EventGalleryMobile from "../bundles/EventGalleryMobile";

// This is how react_on_rails can see the BrowseEvent in the browser.
ReactOnRails.register({
  BrowseEvent
});

// TODO: put this out in its own bundle
ReactOnRails.register({
  EventGalleryMobile
});
