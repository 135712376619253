import React from "react";
import Pluralize from "react-pluralize";
import { withCartIdParam } from "../../../utils/BrowserHelper";
import PropTypes from "prop-types";
import { LightBoxViewCart } from "./vectors/AddToCartIcon";
import ContrastForegroundUtility from "../../../utils/ContrastForegroundUtility";

interface EmbedHeaderProps {
  cart: {
    photos: Array<{}>;
  };
}

class EmbedHeader extends React.Component<EmbedHeaderProps> {
  photosCount = () =>
    this.props.cart && this.props.cart.photos
      ? this.props.cart!.photos.length
      : 0;

  static contextTypes = {
    cartId: PropTypes.number,
    isEmbed: PropTypes.bool,
    pageConfiguration: PropTypes.shape({
      primaryColor: PropTypes.string,
      secondaryColor: PropTypes.string,
      accentColor: PropTypes.string
    })
  };

  render() {
    return (
      <div className="dv-embed-header">
        <a href="https://geosnapshot.com" target="_blank">
          <div className="embed-branding"></div>
        </a>

        <div className="embed-checkout">
          <span className="cart-photos">
            <Pluralize singular="photo" count={this.photosCount()} />
          </span>
          <a
            href={withCartIdParam(
              "/checkout?source=embed-nav-bar",
              this.context.cartId,
              this.context.isEmbed
            )}
            target={this.context.isEmbed ? "_blank" : ""}
            className="btn btn-primary checkout"
          >
            <LightBoxViewCart
              color={ContrastForegroundUtility.fgColor(
                this.context.pageConfiguration.primaryColor
              )}
            />{" "}
            View Cart
            <span className="photos">&nbsp;({this.photosCount()})</span>
          </a>
        </div>
      </div>
    );
  }
}

export default EmbedHeader;
