import React from "react";
import CopyBtn from "./vectors/CopyBtn";
import EmailIcon from "./vectors/EmailIcon";

interface EventSupportEmailPopupProps {
  showEmail: boolean;
  closePopup: () => void;
  eventSupportLabel: string;
  supportEmail: string;
}

const EventSupportEmailPopup: React.FC<EventSupportEmailPopupProps> = ({
  showEmail,
  closePopup,
  eventSupportLabel,
  supportEmail
}) => {
  return showEmail ? (
    <div className="geo-email-div">
      {" "}
      <div className="geo-email-details-div">
        <EmailIcon color={"white"} />
        <div className="geo-email-details">
          <span className="title">
            {eventSupportLabel ? eventSupportLabel : "Contact Support"}
          </span>
          <a
            className="email"
            href={`mailto:${supportEmail}?cc=support@geosnapshot.com`}
          >
            {supportEmail}
          </a>
        </div>
      </div>
      <span
        className="copy-icon"
        id="copyIconDesktop"
        onClick={() => (
          navigator.clipboard.writeText(`${supportEmail}`), //@ts-ignore
          toastr.success("Copied")
        )}
      >
        <CopyBtn color={"white"} />
      </span>
    </div>
  ) : null;
};

export default EventSupportEmailPopup;
