import React from "react";
const sidebarArrow = require("../styles/sidebar-arrow.svg");
const sidebarClose = require("../styles/sidebar-close.svg");
const menu = require("../styles/menu.svg");
import TreeView from "../../TreeView";
import Telemetry from "../../../utils/rudderstackAnalytics";

interface SidebarProps {
  isSidebarOpen: boolean;
  toggleSideBar: () => void;
  userSignedIn: boolean;
  eventId: number;
  albumObject: any;
}

const Sidebar: React.FC<SidebarProps> = ({
  isSidebarOpen,
  toggleSideBar,
  userSignedIn,
  eventId,
  albumObject
}) => {
  const handleHideTooltip = () => $("#sidebar-arrow").tooltip("hide");

  const handleToggle = (msg: String) =>
    $("#sidebar-arrow")
      .attr("data-original-title", `${msg}`)
      //@ts-ignore
      .tooltip("show");

  return (
    <>
      {userSignedIn && (
        <div
          className={`fake-sidebar ${isSidebarOpen ? "open" : "closed"}`}
          onClick={() => {
            if (!isSidebarOpen) {
              Telemetry.track("content-library-sidebar-opened");
            }
            toggleSideBar();
          }}
          onMouseEnter={() =>
            handleToggle(isSidebarOpen ? "Hide" : "Jump to an event or album")
          }
          onMouseLeave={handleHideTooltip}
        >
          <div className="sidebar-icons-div">
            <img src={menu} />
          </div>

          <div className="arrow-circle" id="sidebar-arrow-circle">
            <img
              className="arrow-btn"
              src={isSidebarOpen ? sidebarClose : sidebarArrow}
              id="sidebar-arrow"
              data-toggle="tooltip"
              aria-hidden="true"
              data-placement="right"
            />
          </div>
        </div>
      )}
      {isSidebarOpen && userSignedIn && (
        <TreeView currentEventId={eventId} albumObject={albumObject} />
      )}
    </>
  );
};

export default Sidebar;
